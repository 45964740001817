import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'

/**
 * 解析 url search 参数
 * @param {string} query
 */
export function queryString (query = '') {
  let match
  const pl = /\+/g
  const search = /([^&=]+)=?([^&]*)/g
  const decode = function (s) {
    return decodeURIComponent(s.replace(pl, ' '))
  }
  if (query[0] === '?') query = query.substring(1)
  const urlParams = {}
  while ((match = search.exec(query)) !== null) {
    urlParams[decode(match[1])] = decode(match[2])
  }
  return urlParams
}

/**
 * 默认会自动解析 search 与 hash 中所有查询参数
 */
export function getQueryString () {
  const searchQuery = queryString(location.search.substring(1))
  const hashQuery = queryString(location.hash.split('?')[1])
  return _.assign({}, searchQuery, hashQuery)
}

/**
 * 判断是否是安卓设备
 */
function _isAndroid () {
  const ua = navigator.userAgent
  return ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
}

export const isAndroid = _isAndroid()

/**
 * 判断是否是 ios 设备
 */
export function _isIOS () {
  const ua = navigator.userAgent
  return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

export const isIOS = _isIOS()

/**
 * 获取 ios 版本号
 */
export function getIOSVersion () {
  const uaMatch = navigator.userAgent.match(/os\s+(\d+)/i)
  return uaMatch ? uaMatch[1] : NaN
}

/**
 * 判断是否为 IE 浏览器
 */
function _isIE () {
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  } else {
    return false
  }
}
export const isIE = _isIE()

/**
 * scroll
 * @param {number} number 距离
 * @param {number} time 时间
 */
export function scrollTop (number = 0, time) {
  if (!time) {
    document.body.scrollTop = document.documentElement.scrollTop = number
    return number
  }
  const spacingTime = 20 // 设置循环的间隔时间  值越小消耗性能越高
  let spacingInex = time / spacingTime // 计算循环的次数
  let nowTop = document.body.scrollTop + document.documentElement.scrollTop // 获取当前滚动条位置
  let everTop = (number - nowTop) / spacingInex // 计算每次滑动的距离
  let scrollTimer = setInterval(() => {
    if (spacingInex > 0) {
      spacingInex--
      scrollTop(nowTop += everTop)
    } else {
      clearInterval(scrollTimer) // 清除计时器
    }
  }, spacingTime)
}


export function dealVersion () {
  // function randomKey(){
  //   var s1="";
  //   for(var k=0;k<8;k++){
  //       var z=[0,1,2,3,4,5,6,7,8,9,"a","b","c","d","e","f"];
  //       var m=z[Math.floor(Math.random() * z.length)];
  //       s1 +=m;
  //   }
  //   return s1;
  // }
  
  // axios.get(`https://qiniucdn.jiliguala.com/official/website/version_0200.json?v=${randomKey()}`).then(res=> {
  //   if(res.status === 200){
  //     // 请求成功
  //     let env = getEnv()

  //       const {
  //         appVersion,
  //         appUpdateTime
  //       } = res.data.data[env]
  //       $('#version').text(appVersion)
  //       let time = moment(appUpdateTime).format('YYYY/MM/DD')
  //       $('#time').text(time)
  //   }
  // })
}

export function getEnv(){
  var env = (origin.indexOf('//fat') !== -1 || origin.indexOf('//localhost') !== -1) ? 'fat': 'prod'
  return env
}