import './index.less'
import '../../public/animation.gsap'
import 'swiper/swiper-bundle.css'
import Swiper from 'swiper/bundle'
import { track } from '../../utils/amplitude.tracker'
import { getQueryString, getEnv } from '../../utils/index'
import axios from 'axios'
import moment from 'moment'

$(function () {
  let bannerList = [{
    "name": "a",
    "date": "2020/02/02",
    "creator": "minza",
    "status": "on",
    "sort": 99,
    "img": [
    "https://qiniucdn.jiliguala.com/appBanner/473422152f20e9f637514e16b6107629_1629165054711_banner-pc.png",
    "https://qiniucdn.jiliguala.com/appBanner/468a50725b076d1ac4c352503cd0ab67_1629165063754_banner-mobile.png"
    ],
    "link": ""
  }]

  /** 埋点 Start **/
  const { channel } = getQueryString()
  const search = window.location.search
  const delayTime = 200

  let swiperList = '',
    loop = false
  if (bannerList.length > 0) {
    swiperList += bannerList
      .map(
        (i) =>
          `
    <div class="swiper-slide bg-img" style="background-image: url('${i.img[0]}');" data-url="${i.link}">
      
    </div>
    `,
      )
      .join('')
  } else {
    swiperList = `
      <div class="swiper-slide" style="background-image: url('https://gaeacdn.jiliguala.com/jlgl/website/20210419/0526/72143a6993e9fb311e45fe4edf123647.png');">
      
      </div>
    `
  }
  if (bannerList.length > 1) {
    // 只有一张图片不轮播
    loop = true
  }
  document
    .getElementsByClassName('swiper-wrapper')[0]
    .insertAdjacentHTML('afterbegin', swiperList)
  var swiper = new Swiper('.swiper-container', {
    grabCursor: true,
    loop: loop,
    pagination: loop
      ? {
          el: '.swiper-pagination',
          bulletActiveClass: 'swiper-active',
          clickable: true,
        }
      : {
          el: null,
        },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  })
  if (loop) {
    // 只有一张图片不轮播
    $('.module-1')
      .eq(0)
      .hover(
        function () {
          $(this).addClass('banner-hover')
        },
        function () {
          $(this).removeClass('banner-hover')
        },
      )
    $('.left')
      .eq(0)
      .on('click', () => {
        swiper.slidePrev()
      })
    $('.right')
      .eq(0)
      .on('click', () => {
        swiper.slideNext()
      })
  }

  $('.swiper-wrapper').on('click', '.swiper-slide', function () {
    let link = $(this).data('url')
    console.log('dddddd', link)
    if (link) {
      window.open(link)
    }
  })

  track('OfficialSite_view', {
    Channel: channel,
    Envioronment: 'PC',
  })
  $('#course-page').on('click', function () {
    track('ContentIntro_click', {
      Channel: channel,
      Envioronment: 'PC',
    })
    let url = `./course.html${search}`
    setTimeout(() => {
      location.href = url
    }, delayTime)
  })
  $('#about-page').on('click', function () {
    track('AboutJLGL_click', {
      Channel: channel,
      Envioronment: 'PC',
    })
    let url = `./aboutus.html${search}`
    setTimeout(() => {
      location.href = url
    }, delayTime)
  })
  $('#free-page').on('click', function () {
    track('FreeCourse_click', {
      Channel: channel,
      Envioronment: 'PC',
    })
    let url = `./freetrial.html${search}`
    setTimeout(() => {
      location.href = url
    }, delayTime)
  })
  $('.course-item').on('click', function () {
    let tag = $(this).attr('data-tag')
    track('RichCont_click', {
      Channel: channel,
      Envioronment: 'PC',
      Course: tag,
    })
    let url = `./course.html${search}#${tag}`
    setTimeout(() => {
      location.href = url
    }, delayTime)
  })
  $('.course-next > a').on('click', function () {
    track('RichCont_More_click', {
      Channel: channel,
      Envioronment: 'PC',
    })
    let url = `./course.html${search}`
    setTimeout(() => {
      location.href = url
    }, delayTime)
  })
  $('#join-us').on('click', function () {
    track('JoinUs_click', {
      Channel: channel,
      Envioronment: 'PC',
    })
    let url = `https://app.mokahr.com/apply/jiliguala/5662#/`
    setTimeout(() => {
      window.open(url)
    }, delayTime)
  })
  $('#exp-store').on('click', function () {
    track('ExperienceCenter_click', {
      Channel: channel,
      Envioronment: 'PC',
    })
    let url = `https://spa.jiliguala.com/estore/experienceStore.html`
    setTimeout(() => {
      window.open(url)
    }, delayTime)
  })
  /** 埋点 End **/

  var controller = new SM.Controller()
  new SM.Scene({
    triggerElement: '#trigger1',
    triggerHook: 0.9,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setClassToggle('#module-2-title', 'animation-none')
    .addTo(controller) // assign the scene to the controller

  new SM.Scene({
    triggerElement: '#trigger2',
    triggerHook: 1,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setClassToggle('.course-item', 'animation-none')
    .addTo(controller) // assign the scene to the controller

  // var controller2 = new SM.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}})

  // new SM.Scene({triggerElement: "#trigger3"})
  //     .setTween("#module-video > div", {y: "80%", ease: Linear.easeNone})
  // 		// .addIndicators()
  // 		.addTo(controller2);
  var controller2 = new SM.Controller({
    globalSceneOptions: { triggerHook: 'onEnter', duration: '200%' },
  })

  ///////
  new SM.Scene({ triggerElement: '#trigger3' })
    .setTween('#module-video > div', { y: '20%', ease: Linear.easeInOut, force3D: true })
    // .addIndicators()
    .addTo(controller2)

  var showStyle = { opacity: 1, transform: 'matrix(1, 0, 0, 1, 0, 0)', ease: Linear.easeInOut }
  var tween = new TimelineMax()
    .add(TweenMax.to($('#text1'), 0.5, { ...showStyle }))
    .add(TweenMax.to($('#text2'), 0.3, { ...showStyle, opacity: 0.5 }), 0.3)
  // .add(TweenMax.to($('#text30'),0.5, showStyle))
  // .add(TweenMax.to($('#text4'),0.1, showStyle))
  // .add(TweenMax.to($('#text5'),0.08, showStyle))
  // .add(TweenMax.to($('#text6'),0.1, showStyle))
  // .add(TweenMax.to($('#text7'),0.12, showStyle))
  // .add(TweenMax.to($('#text8'),0.14, showStyle))
  // .add(TweenMax.to($('#text2',1, {opacity: 0})))
  new SM.Scene({
    triggerElement: '#trigger4',
    triggerHook: 0.8,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setTween(tween)
    .addTo(controller) // assign the scene to the controller

  new SM.Scene({
    triggerElement: '#trigger41',
    triggerHook: 0.6,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setTween(TweenMax.to($('#text30'), 0.5, showStyle))
    .addTo(controller) // assign the scene to the controller

  ///  muse 先后出现的动画

  var muse = new TimelineMax()
    // .add(TweenMax.to($('#muse1'),0.5, showStyle))
    .add(TweenMax.to($('#muse1'), 0.5, { ...showStyle, opacity: 1 }))
    .add(TweenMax.to($('#muse2'), 0.2, { ...showStyle, opacity: 0.8 }), 0.3)

  new SM.Scene({
    triggerElement: '#trigger5',
    triggerHook: 0.8,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setTween(muse)
    .addTo(controller) // assign the scene to the controller

  new SM.Scene({
    triggerElement: '#trigger6',
    triggerHook: 0.9,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setClassToggle('.muse-item', 'animation-none')
    .addTo(controller) // assign the scene to the controller

  /// muse 轮流替换背景图的动画

  const originList = [
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/0526/86b1c2acc99c2d188dbe6927dbd145ea.png',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/0526/e5b7c6e9f1473e2a31913db64cdaa952.png',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/0526/1f3e568d62bb18b0870a01cb2e34b351.png',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/0526/1e5824bc8d8dccbedbf93faae1a7c3a9.png',
  ]
  const nextList = [
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/muse/94da26e65fecddf2bf9fe034f265b639.gif',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/muse/edcdc54f45345d1a33652010d1c5afe8.gif',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/muse/a2ce682281c80ba1af40612935684a56.gif',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/muse/dcbe2eb0212a0e4ba4ba535990070209.gif',
  ]

  const hoverList = [
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/muse/0f1b18d301ec6cf7bb9730cfb0cce309.gif',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/muse/22aa73c774462a6b3dc323a6ecf1f73d.gif',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/muse/3299b7355fdde4150c0380014a2d5674.gif',
    'https://gaeacdn.jiliguala.com/jlgl/website/20210419/muse/9d01db0c12b194bbc7066ba1ba10ba3c.gif',
  ]

  let museIndex = 0
  let hoverStatus = false // 是否鼠标覆盖，覆盖之后取消动画
  var museSti = setInterval(() => {
    let currentIndex = museIndex % 4
    let nextIndex = ''
    let current_id = `#muse${4 + currentIndex}`
    let MuseShow = $('#muse7').css('opacity') === '1' // 是否已经出现，出现之后再展示替换动画
    if (!MuseShow || hoverStatus) return false // 未展示界面|| 鼠标hover暂停动画
    museIndex++
    nextIndex = museIndex % 4
    let next_id = `#muse${4 + nextIndex}`
    $(current_id).children('img').attr('src', originList[currentIndex])
    $(next_id).children('img').attr('src', nextList[nextIndex])
  }, 3000)

  // 鼠标覆盖动画
  for (let i = 0; i < 4; i++) {
    let id = `#muse${4 + i}`
    $(id).hover(
      function (e) {
        let MuseShow = $('#muse7').css('opacity') === '1' // 是否已经出现，出现之后再展示替换动画
        if (!MuseShow) {
          // 未展示玩出现动画的时候，不显示hover效果
          return false
        }
        track('Muse_click', {
          Channel: channel,
          Envioronment: 'PC',
        })
        e.stopPropagation()
        stickMuse($(this), true)
      },
      function (e) {
        e.stopPropagation()
        stickMuse($(this), false)
      },
    )
  }

  function stickMuse(dom, tag) {
    let index = dom.attr('data-index')
    hoverStatus = tag
    let src = tag ? hoverList[index] : originList[index]
    dom.children('img').attr('src', src)
    if (tag) {
      dom.addClass('stick-muse')
      $('.muse-bg').eq(0).addClass('stick-bg')
    } else {
      $('.muse-bg').eq(0).removeClass('stick-bg')
      setTimeout(() => {
        dom.removeClass('stick-muse')
      }, 50)
    }
  }

  // 孩子图片动画

  new SM.Scene({
    triggerElement: '#trigger7',
    triggerHook: 0.8,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setClassToggle('#module-child', 'cir')
    .addTo(controller) // assign the scene to the controller

  new SM.Scene({
    triggerElement: '#trigger7',
    triggerHook: 0.7,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setClassToggle('#icon_pic', 'icon_cir')
    .addTo(controller) // assign the scene to the controller

  // 孩子模块的文字动画

  var ChildItem = new TimelineMax()
    .add(TweenMax.to($('#child-t1'), 0.5, { ...showStyle }))
    .add(TweenMax.to($('#child-t2'), 0.3, { ...showStyle, opacity: 0.5 }, 0.3))

  new SM.Scene({
    triggerElement: '#trigger8',
    triggerHook: 0.75,
    // duration: 1000, // the scene should last for a scroll distance of 100px
    // offset: 300 // start this scene after scrolling for 50px
  })
    .setTween(ChildItem)
    .addTo(controller) // assign the scene to the controller

  // function throttle  (func, delay) {
  //   var prev = Date.now()
  //   return function () {
  //     var context = this
  //     var args = arguments
  //     var now = Date.now()
  //     if(now -prev >= delay) {
  //       func.apply(context, args)
  //       prev = Date.now()
  //     }
  //   }
  // }

  let scrollTimer = null
  let tStart = 0
  let tEnd = 0 // 停止
  let scrollStart = false // 开始滚动的时候隐藏header
  $(window).on('scroll', () => {
    clearTimeout(scrollTimer)
    if (!scrollStart) {
      scrollStart = true
      $('#module-header').addClass('scrolling')
    }
    scrollTimer = setTimeout(isScrollEnd, 50)
    tStart = $(window).scrollTop()
  })

  function isScrollEnd() {
    tEnd = $(window).scrollTop()
    if (tEnd === tStart) {
      scrollStart = false
      let winHeight = $(window).innerHeight() - 88
      if (tEnd >= winHeight) {
        $('#module-header').addClass('sec-header')
      } else {
        $('#module-header').removeClass('sec-header')
      }
      $('#module-header').removeClass('scrolling')
      clearTimeout(scrollTimer)
    }
  }

  ;(function () {
    let top = $(window).scrollTop()
    let winHeight = $(window).innerHeight() - 88
    if (top >= winHeight) {
      $('#module-header').addClass('sec-header')
    } else {
      $('#module-header').removeClass('sec-header')
    }
  })()
})
