// import amplitude from 'amplitude-js'
import { init, logEvent } from 'jlgldb-track'
const isProduction = process.env.NODE_ENV === 'production'
init(isProduction ? '3d9e4b23a1ebef22aa336f5e3fd50199' : '2c75a19efe342690f381a32a65d7e017')

// amplitude.init(isProduction ? '3d9e4b23a1ebef22aa336f5e3fd50199' : '2c75a19efe342690f381a32a65d7e017')
/**
 * 打点函数
 * @param {string} eveName 事件名称
 * @param {object} eveProps 事件属性
 * @param {function} resolve 回调函数
 */
export function track (eveName = '', eveProps = {}, resolve) {
  return logEvent(eveName, eveProps, resolve)
}
